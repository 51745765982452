@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,800&display=swap&subset=cyrillic,cyrillic-ext');

#map {
    width: 100%;
    height: 100%;
}

body {
    margin:0;
    padding:0;
    font-family:'Open Sans', Arial, sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #020108;
}

::-webkit-scrollbar-thumb {
    background-color: #3b383a;
}
.wrapper{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.page {
    display: flex;
    flex-direction: row;
    flex: 1;
}


.left-column {
    padding: 0;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #020108;
    box-shadow: 0px 0px 16px rgba(67, 29, 11, 0.19);
    color:white;
    width: 354px;

    img.logo{
        margin: 0 0 23px 17px;
    }
}

.right-column {
    display: flex;
    flex: 1;
    align-items: stretch;
    div{
        flex: 1;
    }
}


@media (max-width: 768px) {
    .page {
    }
}

.marker {
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.barCharts{
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    background: lavender;
    .closeBtn{
        float: right;
        color:coral;
    }
}

.cafeCard{
    padding: 22px 17px 0px;
    ::-webkit-scrollbar-track {
        background: var(--bg-color);
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(2, 1, 7,0.2);
    }



    &-absolute{
        position: relative;
        bottom: 0;
        left:0;
        right:0;
        background: #CE556A;
        border-radius: 6px 6px 0 0;
        box-shadow: 0px -4px 16px var(--bg-color);
        color:black;
        min-height:100px;

        &>:last-child{
            padding-bottom: 24px;
        }

        .cafeCard--address{
            white-space: normal !important;
        }

        .cafeCard--content{
            display: inline !important;
        }
    }

    .cafeCard--header{
        font-weight: 800;
        font-size: 20px;
        line-height: 110%;
        margin: 0 18px 4px 0;

    }

    .cafeCard--closeBtn{
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }

    .cafeCard--content{
        display: flex;
        font-size: 16px;

        .cafeCard--rating{
            display: inline;
            &:after {
                content: "★ · ";
                word-spacing: 2px;
                white-space: pre;
            }
        }
        .cafeCard--address{
            display: inline;
            white-space: nowrap;
            overflow:hidden;
            text-overflow: ellipsis;
        }

        &--shedule{
            flex-direction: column;
            margin-top: 16px;
            &--row{
                flex-direction: row;
                display: flex;
                font-size: 16px;
                line-height: 16px;
                align-items: baseline;
                sup{
                    font-size: 10px;
                    vertical-align:top;
                    position: relative;
                    top: -1px;
                }
                :first-child{
                    flex:1;
                }
                :last-child{
                    flex:5;
                }
            }
        }

        &--flex{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 14px;
            >a:hover{
                opacity: 0.8;
            }
            a:first-child{
                margin-left: 0px;
            }
        }

        &--phones{
            padding-bottom: 0px;
            margin-top: 6px;
        }

        &--website{
            display: inline-block;
            padding: 1px 12px 2px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 999px;
            text-decoration:none;
            color: inherit;
            margin-right: 16px;
            line-height: 18px;
        }

        &--insta{
            display: block;
            margin: 5px 10px 5px 0;
        }

        &--remote{
            overflow-y: auto;
            max-height: 5.5em;
            margin-right: -18px;
            margin-top: 12px;
            padding-right: 18px;
            line-height:19px;
            scrollbar-width: thin;
            scrollbar-color: rgba(2, 1, 7,0.2) var(--bg-color);
        }
    }
}

canvas#legend{
    width: 100%;
    height: 25px;
}

#cafeList{
    display: flex;
    flex-grow: 1;
    padding: 0;
}

.sidebar{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position:relative;



    ul.scrollable{
        position: absolute;
        top: 64px;
        bottom: 0;
        left:0;
        right:0;
        display: block;
        overflow-y: scroll;
        padding-left: 8px;
        margin: 10px 0 0;
        scrollbar-width: thin;
        scrollbar-color: #3b383a #020108;
    }
}

.filters {
    padding-bottom:32px;
    padding-left: 17px;

    .filter{
        background: rgba(196, 196, 196, 0.2);
        border-radius: 4px;
        border:none;
        color: #D0CDC6;
        font-size: 16px;
        font-style: italic;
        padding: 4px 12px;
        width:294px;
        &-rounded{
            border-radius: 999px;
            font-style: normal;
        }
    }

    .filterOpenNow,
    .filterEcoFriendly {
        padding: 2px 12px 4px;
        display: inline;
        cursor: pointer;

        &.__checked {
            background: #D0CDC6;
            color: #020108;
            &:hover {
                background: white;
                color: #020108;
            }
        }
        &:hover{
            background: rgba(196, 196, 196, 0.6);
            color: #D0CDC6;

        }
    }

    .filterEcoFriendly {
        margin-left: 5px;
    }

    .searchbar{
        margin-bottom: 12px;
    }
}
.scrollable {
    display: flex;
    flex-direction: column;

    .listItem {
        padding: 11px 3px 9px 11px;
        margin-bottom: 4px;
        cursor: pointer;
        border: 1px solid black;
        box-sizing: border-box;
        border-radius: 8px;
        &:hover, &.active{
            border: 1px solid var(--border-color);
        }
        &:last-of-type{
            margin-bottom: 32px;
        }
    }

}

.connectingLineLayerSvg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.overlay{
    position: absolute;
    margin-top: -25px;
    display: flex;
    align-content: stretch;
    width: 100%;
    >div{
        flex: 1;
        &:first-child{
            padding-left:6px;
        }
        &:last-child{
            text-align: end;
            padding-right:6px;
        }
        &:nth-child(2){
            text-align: center;
            flex-grow: 0.5;
        }
        span{
            opacity: 0.5;
        }
    }

}

.marker {
		//width:1em;
		//height:1em;
		//font-size: 24px;
    //cursor:pointer;
    ////animation: shadow-pulse 1s infinite;
    //z-index: 20;
    ////background-color: white;
    //padding:1px 2px;
}
//@keyframes shadow-pulse
//{
    //0% {
        //box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    //}
    //100% {
        //box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    //}
//}

.resetPopupContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    pointer-events: none;
}

.resetPopup {
    background: #fff;
    padding: 40px 32px;
    color: #000;
    border-radius: 8px;
    width: 520px;
    font-size: 16px;

    button {
        border: 1px solid #000;
        border-radius: 15px;
        pointer-events: auto;
        font-size: 16px;
        padding: 3px 12px 4px;
        cursor: pointer;
        background: white;
        &:hover{
            opacity: 0.8;
        }
    }
}

.resetPopup_title {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 8px;
}

.cafe-active{
	pointer-events: none;
	//position: relative;
	left: -14px;
	top: -13px;
	i{
		font-size: 24px;
		position: absolute;
		left: 2px;
		z-index: 1;
	}
	&:after{
		content: '';
		display: block;
		background: radial-gradient(closest-side, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
		width: 50px;
		height: 50px;
		position: absolute;
		top: -13px;
		left: -12px;
		z-index: 0;
	}
	.ray{
    width: 50px;
		//margin: 1px 0;
    height: 4px;
    position: absolute;
		z-index: 1;
    top: 11px;
    left: -10px;
		animation-name: ray;
		animation-duration: 400ms;
		animation-iteration-count: infinite;
		animation-direction: alternate;
		&:before{
			content: '';
			background: black;
			border-radius: 4px;
			display: block;
			height: 100%;
			width: 10px;
		}
	}
	.ray1{animation-name: ray1;}
	.ray2{animation-name: ray2;}
	.ray3{animation-name: ray3;}
	.ray4{animation-name: ray4;}
	.ray5{animation-name: ray5;}
	.ray6{animation-name: ray6;}
	.ray7{animation-name: ray7;}
	.ray8{animation-name: ray8;}
}

$ray-pos-from: -8px;
$ray-pos-to: -3px;
@keyframes ray1{
  0%{transform: rotate(0deg) translateX($ray-pos-from);}
  100%{transform: rotate(0deg) translateX($ray-pos-to);}
}
@keyframes ray2{
  0%{transform: rotate(45deg) translateX($ray-pos-from);}
  100%{transform: rotate(45deg) translateX($ray-pos-to);}
}
@keyframes ray3{
  0%{transform: rotate(90deg) translateX($ray-pos-from);}
  100%{transform: rotate(90deg) translateX($ray-pos-to);}
}
@keyframes ray4{
  0%{transform: rotate(135deg) translateX($ray-pos-from);}
  100%{transform: rotate(135deg) translateX($ray-pos-to);}
}
@keyframes ray5{
  0%{transform: rotate(180deg) translateX($ray-pos-from);}
  100%{transform: rotate(180deg) translateX($ray-pos-to);}
}
@keyframes ray6{
  0%{transform: rotate(225deg) translateX($ray-pos-from);}
  100%{transform: rotate(225deg) translateX($ray-pos-to);}
}
@keyframes ray7{
  0%{transform: rotate(270deg) translateX($ray-pos-from);}
  100%{transform: rotate(270deg) translateX($ray-pos-to);}
}
@keyframes ray8{
  0%{transform: rotate(315deg) translateX($ray-pos-from);}
  100%{transform: rotate(315deg) translateX($ray-pos-to);}
}
